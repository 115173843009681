@import '~bootstrap-utilities';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import 'theme.scss';
@import "~angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.table-fixed {
  table-layout: fixed;
}

table {
  background-color: white;
  border-spacing: 0;

  &.outlined {
    border: solid 1px var(--light-gray);
  }

  th,
  td {
    padding: 0.5em;
  }

  thead {
    th,
    td {
      background-color: lighten($light-gray, 10%);
      border-bottom: solid 1px var(--light-gray);
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: lighten($light-gray, 33%);
    }
  }

  tr:not(:last-child) {
    td,
    th {
      border-bottom: solid 1px var(--light-gray);
    }
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.text-gray {
  color: var(--gray);
}

.text-dark-gray {
  color: var(--dark-gray);
}

.text-small {
  font-size: smaller;
}

.text-large {
  font-size: larger;
}

.font-mono {
  font-family: monospace;
}

[role='button'], [role='menuitem'] {
  cursor: default;
}

[role='link'] {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.w-pt5em {
  width: .5em;
}

.w-1em {
  width: 1em;
}

.w-2em {
  width: 2em;
}

.w-3em {
  width: 3em;
}

.w-4em {
  width: 4em;
}

.w-5em {
  width: 5em;
}

.w-6em {
  width: 6em;
}

.w-7em {
  width: 7em;
}

.w-8em {
  width: 8em;
}

.w-9em {
  width: 9em;
}

.w-10em {
  width: 10em;
}

.w-11em {
  width: 11em;
}

.w-12em {
  width: 12em;
}

.w-13em {
  width: 13em;
}

.w-14em {
  width: 14em;
}

.w-15em {
  width: 15em;
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-7 {
  width: 7%;
}

.w-8 {
  width: 8%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-33 {
  width: 33%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-66 {
  width: 66%;
}

.w-95 {
  width: 95%;
}

.h-0header {
  height: calc(100% - 150px);
}

.mat-chip-gray-outline {
  display: inline-block;
  border: solid 0.1em var(--gray);
  border-radius: 1em;
  padding: 0.25em 0.5em;
  white-space: nowrap;
  background-color: white;
  color: var(--dark-gray);
}

.mat-chip-green-outline {
  display: inline-block;
  border: solid 0.1em var(--green);
  border-radius: 1em;
  padding: 0.25em 0.5em;
  white-space: nowrap;
  background-color: var(--lime-green);
  color: var(--green);
}

.mat-chip-yolk-outline {
  display: inline-block;
  border: solid 0.1em var(--dark-gray);
  border-radius: 1em;
  padding: 0.25em 0.5em;
  white-space: nowrap;
  background-color: #f8de8f;
  color: var(--dark-gray);
}

.hcc-logo-horizontal {
  width: 300px;
}

.clipped {
  overflow: hidden;
}

.font-larger {
  font-size: larger;
}

.blue {
  color: var(--primary);
}

.card {
  padding: 1rem;
  background-color: white;
  margin: 1em;
  flex-grow: 1;
}

.card-outlined {
  border: solid 1px var(--light-gray);
  border-radius: 0.5em;
}

.container {
  padding: 1rem;
  background-color: #f6f9fc;
  min-height: calc(100% - 151px);
}

.flex-grow {
  flex: 1 0 auto;
}

.flex-child {
  flex: 0 0 auto;
}

.flex-child-25 {
  flex: 0 0 calc(25%);
}

.flex-child-33 {
  flex: 0 0 calc(33%);
}

.flex-child-40 {
  flex: 0 0 calc(40%);
}

.flex-child-45 {
  flex: 0 0 calc(45%);
}

.flex-child-50 {
  flex: 0 0 calc(50%);
}

.flex-child-55 {
  flex: 0 0 calc(55%);
}

.flex-child-60 {
  flex: 0 0 calc(60%);
}

.flex-child-66 {
  flex: 0 0 calc(66%);
}

.flex-child-75 {
  flex: 0 0 calc(75%);
}

.flex-child-100 {
  flex: 0 0 calc(100%) !important;
}

// * Generics
.pointer {
  cursor: pointer;
}

// * Pseudo Bootstrapping
.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.col-1 {
  flex: 0 0 auto;
  width: calc(1 / 12 * 100%);
  position: relative;
}

.col-2 {
  flex: 0 0 auto;
  width: calc(2 / 12 * 100%);
  position: relative;
}

.col-3 {
  flex: 0 0 auto;
  width: calc(3 / 12 * 100%);
  position: relative;
}

.col-4 {
  flex: 0 0 auto;
  width: calc(4 / 12 * 100%);
  position: relative;
}

.col-5 {
  flex: 0 0 auto;
  width: calc(5 / 12 * 100%);
  position: relative;
}

.col-6 {
  flex: 0 0 auto;
  width: calc(6 / 12 * 100%);
  position: relative;
}

.col-7 {
  flex: 0 0 auto;
  width: calc(7 / 12 * 100%);
  position: relative;
}

.col-8 {
  flex: 0 0 auto;
  width: calc(8 / 12 * 100%);
  position: relative;
}

.col-9 {
  flex: 0 0 auto;
  width: calc(9 / 12 * 100%);
  position: relative;
}

.col-10 {
  flex: 0 0 auto;
  width: calc(10 / 12 * 100%);
  position: relative;
}

.col-11 {
  flex: 0 0 auto;
  width: calc(11 / 12 * 100%);
  position: relative;
}

.col-12 {
  flex: 0 0 auto;
  width: calc(12 / 12 * 100%);
  position: relative;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  position: relative;
}

.hidden {
  display: none;
}
