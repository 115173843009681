@use '~@angular/material' as mat;
@import '~@angular/material/theming';

@include mat.core();

$warn: #ff0000;
$warn-dark: darken($warn, 30%);
$warn-light: lighten($warn, 40%);
$orange: #f6891f;
$orange-yellow: #fdce24;
$yellow: #fff000;
$canary-yellow: #ecdf7a;
$lime-green: #acd149;
$green: #3d9600;
$dark-green: darken(#3d9600, 10%);
$olive-green: #6b7630;
$cyan: #00dbf0;
$primary: #005cab;
$accent: #063472;
$purple: #852f78;
$process-black: #231f20;
$dark-gray: #444441;
$gray: #a8a8aa;
$light-gray: #ddd;
$light-orange: #f7f1eb;
$off-white: #eee;

$hlc-dark-purple: #25195f;
$hlc-light-purple: #917DA1;
$hlc-dark-orange: #DC6C1E;
$hlc-light-orange: #F9A87C;
$hlc-xlight-orange: #f7f2eb;

:root {
  --warn: #{$warn};
  --warn-dark: #{$warn-dark};
  --warn-light: #{$warn-light};
  --orange: #{$orange};
  --orange-yellow: #{$orange-yellow};
  --yellow: #{$yellow};
  --canary-yellow: #{$canary-yellow};
  --lime-green: #{$lime-green};
  --green: #{$green};
  --dark-green: #{$dark-green};
  --olive-green: #{$olive-green};
  --cyan: #{$cyan};
  --primary: #{$primary};
  --accent: #{$accent};
  --purple: #{$purple};
  --process-black: #{$process-black};
  --dark-gray: #{$dark-gray};
  --gray: #{$gray};
  --light-gray: #{$light-gray};
  --off-white: #{$off-white};
  --hlc-dark-purple: #{$hlc-dark-purple};
  --hlc-light-purple: #{$hlc-light-purple};
  --hlc-dark-orange: #{$hlc-dark-orange};
  --hlc-light-orange: #{$hlc-light-orange};
  --hlc-xlight-orange: #{$hlc-xlight-orange};
}

$hcc-altaccent: (
  50: #e0fbfd,
  100: #b3f4fb,
  200: #80edf8,
  300: #4de6f5,
  400: #26e0f2,
  500: #00dbf0,
  600: #00d7ee,
  700: #00d2ec,
  800: #00cde9,
  900: #00c4e5,
  A100: #ffffff,
  A200: #d9f9ff,
  A400: #a6f0ff,
  A700: #8cecff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$hcc-primary: (
  50: #e0ebf5,
  100: #b3cee6,
  200: #80aed5,
  300: #4d8dc4,
  400: #2674b8,
  500: #005cab,
  600: #0054a4,
  700: #004a9a,
  800: #004191,
  900: #003080,
  A100: #adc5ff,
  A200: #7aa1ff,
  A400: #477dff,
  A700: #2e6aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$hcc-accent: (
  50: #e1e7ee,
  100: #b4c2d5,
  200: #839ab9,
  300: #51719c,
  400: #2b5287,
  500: #063472,
  600: #052f6a,
  700: #04275f,
  800: #032155,
  900: #021542,
  A100: #7792ff,
  A200: #4469ff,
  A400: #1141ff,
  A700: #0031f6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$hcc-warn: (
  50: #ffe4e0,
  100: #ffbdb3,
  200: #ff9180,
  300: #ff644d,
  400: #ff4326,
  500: #ff2200,
  600: #ff1e00,
  700: #ff1900,
  800: #ff1400,
  900: #ff0c00,
  A100: #ffffff,
  A200: #fff2f2,
  A400: #ffc0bf,
  A700: #ffa7a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$hcc-custom: mat.define-palette($hcc-altaccent, custom);
$hcc-primary: mat.define-palette($hcc-primary);
$hcc-accent: mat.define-palette($hcc-accent);
$hcc-warn: mat.define-palette($hcc-warn);

$hcc-theme: mat.define-light-theme(
  (
    color: (
      primary: $hcc-primary,
      accent: $hcc-accent,
      altaccent: $hcc-altaccent,
      warn: $hcc-warn,
    ),
  )
);

//@include mat.core-theme($hcc-theme);
@include mat.all-component-themes($hcc-theme);
